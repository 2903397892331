/* create columns */
/* create columns */
/* create custom named columns with custom content */
.mc-button {
  margin: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #ffffff;
  background-color: #188803;
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375;
  padding: 0.6875rem 1.5rem;
  min-height: 3rem;
  min-width: 3rem;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  border: 2px solid transparent;
  -webkit-transition: all ease 200ms;
  transition: all ease 200ms;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  vertical-align: middle;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  fill: currentColor;
}
.mc-button.is-hover,
.mc-button:hover {
  background-color: #006902;
  color: #ffffff;
}

.mc-button.is-active,
.mc-button:active {
  background-color: #006902;
}

.mc-button:disabled, .mc-button.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button .mc-button__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.mc-button .mc-button__icon:first-child, .mc-button .mc-button__icon:last-child {
  margin-bottom: -1px;
  margin-top: -1px;
}
.mc-button .mc-button__icon:only-child {
  margin-bottom: 0;
  margin-top: 0;
  width: 2rem;
  height: 2rem;
}
.mc-button.is-focus,
.mc-button:focus {
  -webkit-box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
          box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
}

.mc-button--s {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  padding: 0.3125rem 1rem;
  min-height: 2rem;
  min-width: 2rem;
}
.mc-button--s .mc-button__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.mc-button--s .mc-button__icon:first-child, .mc-button--s .mc-button__icon:last-child {
  margin-bottom: -0.1875rem;
  margin-top: -0.1875rem;
}
.mc-button--s .mc-button__icon:only-child {
  margin-bottom: 0;
  margin-top: 0;
  width: 1.5rem;
  height: 1.5rem;
}
.mc-button--m {
  font-size: 1rem;
  line-height: 1.375;
  padding: 0.6875rem 1.5rem;
  min-height: 3rem;
  min-width: 3rem;
}
.mc-button--m .mc-button__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.mc-button--m .mc-button__icon:first-child, .mc-button--m .mc-button__icon:last-child {
  margin-bottom: -1px;
  margin-top: -1px;
}
.mc-button--m .mc-button__icon:only-child {
  margin-bottom: 0;
  margin-top: 0;
  width: 2rem;
  height: 2rem;
}
.mc-button--l {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  padding: 0.875rem 1.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
}
.mc-button--l .mc-button__icon {
  width: 2rem;
  height: 2rem;
}
.mc-button--l .mc-button__icon:first-child, .mc-button--l .mc-button__icon:last-child {
  margin-bottom: -0.25rem;
  margin-top: -0.25rem;
}
.mc-button--l .mc-button__icon:only-child {
  margin-bottom: 0;
  margin-top: 0;
  width: 2rem;
  height: 2rem;
}
.mc-button--fit {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
}
.mc-button--full {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
@supports ((width: -webkit-fill-available) or (width: -moz-available) or (width: stretch)) {
  .mc-button--full {
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
  }
}
.mc-button--square {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 0;
  padding: 0;
}
.mc-button__icon {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.mc-button__icon:last-child {
  margin-left: 0.5rem;
  margin-right: -0.25rem;
}
.mc-button__icon:first-child {
  margin-right: 0.5rem;
  margin-left: -0.25rem;
}
.mc-button__icon:only-child {
  margin: 0;
}
.mc-button__label {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  pointer-events: none;
}
a.mc-button:disabled, a.mc-button.is-disabled {
  pointer-events: none;
}

.mc-button--solid-primary-02 {
  background-color: #6a7081;
}
.mc-button--solid-primary-02.is-hover,
.mc-button--solid-primary-02:hover {
  background-color: #242938;
}

.mc-button--solid-primary-02.is-active,
.mc-button--solid-primary-02:active {
  background-color: #171b26;
}

.mc-button--solid-primary-02:disabled, .mc-button--solid-primary-02.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--solid-neutral {
  background-color: #333333;
}
.mc-button--solid-neutral.is-hover,
.mc-button--solid-neutral:hover {
  background-color: #191919;
}

.mc-button--solid-neutral.is-active,
.mc-button--solid-neutral:active {
  background-color: #333333;
}

.mc-button--solid-neutral:disabled, .mc-button--solid-neutral.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--solid-danger {
  background-color: #c61112;
}
.mc-button--solid-danger.is-hover,
.mc-button--solid-danger:hover {
  background-color: #8c0003;
}

.mc-button--solid-danger.is-active,
.mc-button--solid-danger:active {
  background-color: #8c0003;
}

.mc-button--solid-danger:disabled, .mc-button--solid-danger.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--bordered {
  color: #188803;
  border-color: #188803;
  background-color: #ffffff;
}
.mc-button--bordered.is-hover,
.mc-button--bordered:hover {
  background-color: #ebf5de;
  color: #006902;
}

.mc-button--bordered.is-active,
.mc-button--bordered:active {
  background-color: #c5e39e;
  color: #035010;
}

.mc-button--bordered.is-active,
.mc-button--bordered:active {
  background-color: #c5e39e;
  color: #035010;
}

.mc-button--bordered:disabled, .mc-button--bordered.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--bordered-primary-02 {
  color: #6a7081;
  border-color: #6a7081;
  background-color: #ffffff;
}
.mc-button--bordered-primary-02.is-hover,
.mc-button--bordered-primary-02:hover {
  background-color: #eeeff1;
  color: #6a7081;
}

.mc-button--bordered-primary-02.is-active,
.mc-button--bordered-primary-02:active {
  background-color: #cfd2d8;
}

.mc-button--bordered-primary-02:disabled, .mc-button--bordered-primary-02.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--bordered-neutral {
  color: #333333;
  border-color: #333333;
  background-color: #ffffff;
}
.mc-button--bordered-neutral.is-hover,
.mc-button--bordered-neutral:hover {
  background-color: #e6e6e6;
  color: #333333;
}

.mc-button--bordered-neutral.is-active,
.mc-button--bordered-neutral:active {
  background-color: #cccccc;
}

.mc-button--bordered-neutral:disabled, .mc-button--bordered-neutral.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--bordered-danger {
  color: #c61112;
  border-color: #c61112;
  background-color: #ffffff;
}
.mc-button--bordered-danger.is-hover,
.mc-button--bordered-danger:hover {
  background-color: #fdeaea;
  color: #8c0003;
}

.mc-button--bordered-danger.is-active,
.mc-button--bordered-danger:active {
  background-color: #f8bcbb;
  color: #530000;
}

.mc-button--bordered-danger.is-active,
.mc-button--bordered-danger:active {
  background-color: #f8bcbb;
  color: #530000;
}

.mc-button--bordered-danger:disabled, .mc-button--bordered-danger.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}

@media screen and (min-width: 680px) {
  .mc-button--s\@from-m {
    font-size: 0.875rem;
    line-height: 1.2857142857;
    padding: 0.3125rem 1rem;
    min-height: 2rem;
    min-width: 2rem;
  }
  .mc-button--s\@from-m .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--s\@from-m .mc-button__icon:first-child, .mc-button--s\@from-m .mc-button__icon:last-child {
    margin-bottom: -0.1875rem;
    margin-top: -0.1875rem;
  }
  .mc-button--s\@from-m .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-m {
    font-size: 1rem;
    line-height: 1.375;
    padding: 0.6875rem 1.5rem;
    min-height: 3rem;
    min-width: 3rem;
  }
  .mc-button--m\@from-m .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-m .mc-button__icon:first-child, .mc-button--m\@from-m .mc-button__icon:last-child {
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .mc-button--m\@from-m .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-m {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    padding: 0.875rem 1.5rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
  }
  .mc-button--l\@from-m .mc-button__icon {
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-m .mc-button__icon:first-child, .mc-button--l\@from-m .mc-button__icon:last-child {
    margin-bottom: -0.25rem;
    margin-top: -0.25rem;
  }
  .mc-button--l\@from-m .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--fit\@from-m {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
  }
  .mc-button--full\@from-m {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  @supports ((width: -webkit-fill-available) or (width: -moz-available) or (width: stretch)) {
    .mc-button--full\@from-m {
      width: -webkit-fill-available;
      width: -moz-available;
      width: stretch;
    }
  }
}

@media screen and (min-width: 1024px) {
  .mc-button--s\@from-l {
    font-size: 0.875rem;
    line-height: 1.2857142857;
    padding: 0.3125rem 1rem;
    min-height: 2rem;
    min-width: 2rem;
  }
  .mc-button--s\@from-l .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--s\@from-l .mc-button__icon:first-child, .mc-button--s\@from-l .mc-button__icon:last-child {
    margin-bottom: -0.1875rem;
    margin-top: -0.1875rem;
  }
  .mc-button--s\@from-l .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-l {
    font-size: 1rem;
    line-height: 1.375;
    padding: 0.6875rem 1.5rem;
    min-height: 3rem;
    min-width: 3rem;
  }
  .mc-button--m\@from-l .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-l .mc-button__icon:first-child, .mc-button--m\@from-l .mc-button__icon:last-child {
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .mc-button--m\@from-l .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-l {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    padding: 0.875rem 1.5rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
  }
  .mc-button--l\@from-l .mc-button__icon {
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-l .mc-button__icon:first-child, .mc-button--l\@from-l .mc-button__icon:last-child {
    margin-bottom: -0.25rem;
    margin-top: -0.25rem;
  }
  .mc-button--l\@from-l .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--fit\@from-l {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
  }
  .mc-button--full\@from-l {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  @supports ((width: -webkit-fill-available) or (width: -moz-available) or (width: stretch)) {
    .mc-button--full\@from-l {
      width: -webkit-fill-available;
      width: -moz-available;
      width: stretch;
    }
  }
  .mc-button--square {
    padding: 0;
  }
}

@media screen and (min-width: 1280px) {
  .mc-button--s\@from-xl {
    font-size: 0.875rem;
    line-height: 1.2857142857;
    padding: 0.3125rem 1rem;
    min-height: 2rem;
    min-width: 2rem;
  }
  .mc-button--s\@from-xl .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--s\@from-xl .mc-button__icon:first-child, .mc-button--s\@from-xl .mc-button__icon:last-child {
    margin-bottom: -0.1875rem;
    margin-top: -0.1875rem;
  }
  .mc-button--s\@from-xl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-xl {
    font-size: 1rem;
    line-height: 1.375;
    padding: 0.6875rem 1.5rem;
    min-height: 3rem;
    min-width: 3rem;
  }
  .mc-button--m\@from-xl .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-xl .mc-button__icon:first-child, .mc-button--m\@from-xl .mc-button__icon:last-child {
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .mc-button--m\@from-xl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-xl {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    padding: 0.875rem 1.5rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
  }
  .mc-button--l\@from-xl .mc-button__icon {
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-xl .mc-button__icon:first-child, .mc-button--l\@from-xl .mc-button__icon:last-child {
    margin-bottom: -0.25rem;
    margin-top: -0.25rem;
  }
  .mc-button--l\@from-xl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--fit\@from-xl {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
  }
  .mc-button--full\@from-xl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  @supports ((width: -webkit-fill-available) or (width: -moz-available) or (width: stretch)) {
    .mc-button--full\@from-xl {
      width: -webkit-fill-available;
      width: -moz-available;
      width: stretch;
    }
  }
}

@media screen and (min-width: 1920px) {
  .mc-button--s\@from-xxl {
    font-size: 0.875rem;
    line-height: 1.2857142857;
    padding: 0.3125rem 1rem;
    min-height: 2rem;
    min-width: 2rem;
  }
  .mc-button--s\@from-xxl .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--s\@from-xxl .mc-button__icon:first-child, .mc-button--s\@from-xxl .mc-button__icon:last-child {
    margin-bottom: -0.1875rem;
    margin-top: -0.1875rem;
  }
  .mc-button--s\@from-xxl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-xxl {
    font-size: 1rem;
    line-height: 1.375;
    padding: 0.6875rem 1.5rem;
    min-height: 3rem;
    min-width: 3rem;
  }
  .mc-button--m\@from-xxl .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-xxl .mc-button__icon:first-child, .mc-button--m\@from-xxl .mc-button__icon:last-child {
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .mc-button--m\@from-xxl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-xxl {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    padding: 0.875rem 1.5rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
  }
  .mc-button--l\@from-xxl .mc-button__icon {
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-xxl .mc-button__icon:first-child, .mc-button--l\@from-xxl .mc-button__icon:last-child {
    margin-bottom: -0.25rem;
    margin-top: -0.25rem;
  }
  .mc-button--l\@from-xxl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--fit\@from-xxl {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
  }
  .mc-button--full\@from-xxl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  @supports ((width: -webkit-fill-available) or (width: -moz-available) or (width: stretch)) {
    .mc-button--full\@from-xxl {
      width: -webkit-fill-available;
      width: -moz-available;
      width: stretch;
    }
  }
}
.mc-link {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: inherit;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: inherit;
  text-decoration: underline;
  -webkit-transition: color ease 200ms;
  transition: color ease 200ms;
  gap: 0.25rem;
  color: #000000;
  position: relative;
  font-size: 1rem;
  line-height: 1.375;
}
.mc-link:focus {
  outline: none;
}
.mc-link:focus::after {
  -webkit-box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
          box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
}
.mc-link.is-visited,
.mc-link:visited {
  color: #333333;
}

.mc-link.is-hover,
.mc-link:hover {
  color: #666666;
}

.mc-link.is-active,
.mc-link:active {
  color: #333333;
}

.mc-link.is-disabled {
  color: #e6e6e6;
  cursor: not-allowed;
}
.mc-link--s {
  font-size: 0.875rem;
  line-height: 1.2857142857;
}
.mc-link--m {
  font-size: 1rem;
  line-height: 1.375;
}
.mc-link::after {
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 0 transparent;
          box-shadow: 0 0 0 0 transparent;
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: -webkit-box-shadow 200ms ease;
  transition: -webkit-box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
}
.mc-link__icon {
  display: block;
  height: 1rem;
  fill: currentColor;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1rem;
}
.mc-link--light {
  color: #ffffff;
}
.mc-link--light.is-visited,
.mc-link--light:visited {
  color: #cccccc;
}

.mc-link--light.is-hover,
.mc-link--light:hover {
  color: #999999;
}

.mc-link--light.is-active,
.mc-link--light:active {
  color: #cccccc;
}

.mc-link--light.is-disabled {
  color: #e6e6e6;
  cursor: not-allowed;
}
.mc-link--primary {
  color: #188803;
}
.mc-link--primary.is-visited,
.mc-link--primary:visited {
  color: #006902;
}

.mc-link--primary.is-hover,
.mc-link--primary:hover {
  color: #035010;
}

.mc-link--primary.is-active,
.mc-link--primary:active {
  color: #006902;
}

.mc-link--primary.is-disabled {
  color: #e6e6e6;
  cursor: not-allowed;
}
.mc-link--primary-02 {
  color: #494f60;
}
.mc-link--primary-02.is-visited,
.mc-link--primary-02:visited {
  color: #343b4c;
}

.mc-link--primary-02.is-hover,
.mc-link--primary-02:hover {
  color: #171b26;
}

.mc-link--primary-02.is-active,
.mc-link--primary-02:active {
  color: #343b4c;
}

.mc-link--primary-02.is-disabled {
  color: #e6e6e6;
  cursor: not-allowed;
}
.mc-link--danger {
  color: #c61112;
}
.mc-link--danger.is-visited,
.mc-link--danger:visited {
  color: #8c0003;
}

.mc-link--danger.is-hover,
.mc-link--danger:hover {
  color: #530000;
}

.mc-link--danger.is-active,
.mc-link--danger:active {
  color: #8c0003;
}

.mc-link--danger.is-disabled {
  color: #e6e6e6;
  cursor: not-allowed;
}

@media screen and (min-width: 680px) {
  .mc-link--s\@from-m {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
  .mc-link--m\@from-m {
    font-size: 1rem;
    line-height: 1.375;
  }
}

@media screen and (min-width: 1024px) {
  .mc-link--s\@from-l {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
  .mc-link--m\@from-l {
    font-size: 1rem;
    line-height: 1.375;
  }
}

@media screen and (min-width: 1280px) {
  .mc-link--s\@from-xl {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
  .mc-link--m\@from-xl {
    font-size: 1rem;
    line-height: 1.375;
  }
}

@media screen and (min-width: 1920px) {
  .mc-link--s\@from-xxl {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
  .mc-link--m\@from-xxl {
    font-size: 1rem;
    line-height: 1.375;
  }
}
.mc-card {
  font-family: "LeroyMerlin", sans-serif;
  max-width: 17.75rem;
}
.mc-card,
.mc-card * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media screen and (min-width: 680px) {
  .mc-card {
    max-width: 18.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .mc-card {
    max-width: 18rem;
  }
}
@media screen and (min-width: 1280px) {
  .mc-card {
    max-width: 17.5rem;
  }
}
@media screen and (min-width: 1920px) {
  .mc-card {
    max-width: 27.25rem;
  }
}
.mc-card__visual {
  margin: 0;
  overflow: hidden;
  position: relative;
}
.mc-card__visual::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 75%;
}
.mc-card__visual--1x1::before {
  padding-top: 100%;
}
.mc-card__visual--16x9::before {
  padding-top: 56.25%;
}
.mc-card__img {
  height: auto;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.mc-card__content {
  padding-top: 1rem;
}
.mc-card__title, .mc-card__subtitle {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.mc-card__title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: 600;
  color: #000000;
}
.mc-card__subtitle {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  font-weight: 400;
  color: #333333;
}
.mc-card__body {
  font-size: 1rem;
  line-height: 1.375;
  color: #191919;
}
.mc-card__body:not(:last-child) {
  margin-bottom: 1rem;
}
.mc-card--bordered {
  background: #ffffff;
  border: 2px solid #808080;
  border-radius: 0.25rem;
  overflow: hidden;
}
.mc-card--bordered .mc-card__content {
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.mc-card--fluid {
  max-width: none;
}

@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Light.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Regular.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Italic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
.product-item {
  width: 100vw;
  max-height: 11vw;
  padding: 10px;
  background-color: white;
}

.product-item__image {
  width: 10%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product-item__image img {
  width: 100%;
  max-height: 100%;
  height: auto;
}

.product-item__details {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.product-item__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.product-item__title {
  color: var(--text-beta-secondary, #333);
  font-size: 12px;
  font-family: "LeroyMerlin", sans-serif;
  margin: 4px;
  margin-left: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 140px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.product-item__date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 54px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  color: var(--primary-01600-default, #188803);
  font-size: 10px;
  font-family: "LeroyMerlin", sans-serif;
}

.product-item__time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 54px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  color: var(--text-beta-primary, #000);
  text-align: right;
  font-size: 10px;
  font-family: "LeroyMerlin", sans-serif;
}

.product-item__price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  color: var(--text-beta-secondary, #333);
  text-align: center;
  font-size: 14px;
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
}

.product-item__quantity {
  border-radius: 4px;
  width: 40px;
  height: 34px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
}

.product-item__quantity p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  color: var(--text-beta-secondary, #333);
  text-align: center;
  font-size: 14px;
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
}