@media screen and (min-width: 800px) and (max-height:1500px) {
   .api-qr-title {
      align-self: center;
      text-align: center;

   }

   .api-qr-subtitle {
      align-self: center;
      text-align: center;

   }

   .api-qr-link {
      align-self: center;
      text-align: center;

   }

   .qr-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 30px;
   }

   .qr-image {
      height: 230px;
      width: 230px;

   }



   .api-qr-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
   }

   .modal-qr-container {
      min-height: 300px;
      min-width: 40vw;
   }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {
   .api-qr-title {
      align-self: center;
      text-align: center;
      font-size: 18px;
   }

   .api-qr-subtitle {
      align-self: center;
      text-align: center;
      font-size: 12px;
   }

   .api-qr-link {
      align-self: center;
      text-align: center;
      font-size: 14px;
   }

   .qr-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 30px;
   }

   .qr-image {
      height: 130px;
      width: 130px;

   }



   .api-qr-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
   }

   .modal-qr-container {
      min-height: 300px;
      min-width: 90vw;
   }
}


@media screen and (min-height: 1500px) {
   .api-qr-title {
      align-self: center;
      text-align: center;
      font-size: 48px;
   }

   .api-qr-subtitle {
      align-self: center;
      text-align: center;
      font-size: 24px;
   }

   .api-qr-link {
      align-self: center;
      text-align: center;
      font-size: 32px;
   }

   .qr-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 100px;
   }

   .qr-image {
      height: 432px;
      width: 432px;

   }



   .api-qr-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
   }

   .modal-qr-container {
      min-height: 1000px;
      min-width: 85vw
   }
}