.LicenceTitle {
  color: var(--greyscale-800, #333);
  text-align: center;
  font-size: 20px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.LicencePlate {


  margin-top: 34px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
