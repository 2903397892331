.menu-screen-container{
    width: 90%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-family: "LeroyMerlin", sans-serif;
}

.svg-container{
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    /* center the svg vertically */
    margin-top: 50%;
    ;
    
}

.mainFinish{
    padding-top: 75px;
    display: flex;
    height: 70vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0px;
  
  
  }
  .titleFinish{
    color: var(--greyscale-800, #333);
  text-align: center;
  font-size: 20px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 60px;
  
  }

@media screen and (min-width: 800px ) and (max-height:1500px) {
 
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {

}


@media screen and (min-height: 1500px) {
    .svg-container{
        scale: 2.5;
        margin-bottom: 10%;
    }

    .titleFinish
    {
        font-size: 30px;
    }

  
}