@media screen and (min-width: 800px) and (max-height:1500px) {
    .modal-add-comment-title {
       align-self: center;
       text-align: left;
       font-size: 1rem;
    }
 
    .modal-add-comment-container {
       max-width: 80vw;
       max-height: 300px;
    }
 }
 
 /* On screens that are 600px or less, set the background color to olive */
 @media screen and (max-width: 800px) {
    .modal-add-comment-title {
       align-self: center;
       text-align: left;
    }
 
    .modal-add-comment-container {
       max-width: 80vw;
       max-height: 300px;
       
    }


    .comment-area {
      max-width: 100%;
      max-height: 144px;
   }
 }
 
 @media screen and (min-height: 1500px) {
    .modal-add-comment-title {
       align-self: center;
       text-align: left;
       font-size: 48px;
       font-weight: 400;
    }
 
    .modal-add-comment-container {
       max-width: 90vw;
       max-height: 460px;
    }

    .comment-area {
      max-width: 100%;
      font-size: 28px;
      max-height: 460px;
   }
 }