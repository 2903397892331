  .camera__enable {
      margin: 0 auto;
      color: white;
    }
    
    .cameraHandler__message {
      padding: 0 20px;
      text-align: center;
      font-size: 30px;
      line-height: 1.2;
    }
    
    .cameraHandler__messageIcon {
      font-size: 40px;
      padding-top: 15px;
    }
    
    .cameraHandler__unsopported {
      display: flex;
      height: 250px;
      align-items: center;
      text-align: center;
    }

    .useQRScanner {
      /* Estilos para o componente useQRScanner */
    }
    
    .modal__overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999;
    }
    
    .modal__content {
      /* Estilos para o conteúdo do modal */
    }
    
    .cameraHandler__message {
      /* Estilos para a mensagem do manipulador da câmera */
    }
    
    .cameraHandler__messageIcon {
      /* Estilos para o ícone da mensagem do manipulador da câmera */
    }
    
    .btn__round {
      /* Estilos para o botão redondo */
    }
    
    .camera__enable {
      /* Estilos para o botão de ativar câmera */
    }
    
    .cameraHandler__unsopported {
      /* Estilos para a mensagem de não suporte à câmera */
    }
    
    .cancel__button{
      z-index: 999;
      position: fixed;
    }