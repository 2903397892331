

@media screen and (min-width: 800px) and (max-height:1500px) {


    .licence-responsive-width{
        width: 400px;
    }

}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {

    .licence-responsive-width{
        min-width: 80vw;
    }

}


@media screen and (min-height: 1500px) {


    .licence-responsive-width{
        min-width: 400px;
    }
}