/* create columns */
/* create columns */
/* create custom named columns with custom content */
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Light.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Regular.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Italic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
.mt-body-l {
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5555555556;
}
.mt-body-l--line-height-m {
  line-height: 1.3333333333;
}
.mt-body-l--semi-bold {
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
}
.mt-body-m {
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}
.mt-body-m--line-height-m {
  line-height: 1.375;
}
.mt-body-m--semi-bold {
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
}
.mt-body-s {
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5714285714;
}
.mt-body-s--line-height-m {
  line-height: 1.2857142857;
}
.mt-body-s--semi-bold {
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
}
.mc-modal {
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 400;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  bottom: 0;
  left: 0;
  outline: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1999999999;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem 1.125rem;
  width: 100vw;
}
.mc-modal,
.mc-modal * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media screen and (min-width: 680px) {
  .mc-modal {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
  }
}
.mc-modal__dialog {
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  opacity: 0;
  max-height: 100%;
  position: relative;
  -webkit-transform: translateY(-25%);
      -ms-transform: translateY(-25%);
          transform: translateY(-25%);
  -webkit-transition: visibility 0s linear 0.4s, opacity 0.4s ease, -webkit-transform 0.4s ease;
  transition: visibility 0s linear 0.4s, opacity 0.4s ease, -webkit-transform 0.4s ease;
  transition: visibility 0s linear 0.4s, transform 0.4s ease, opacity 0.4s ease;
  transition: visibility 0s linear 0.4s, transform 0.4s ease, opacity 0.4s ease, -webkit-transform 0.4s ease;
  visibility: hidden;
  width: 100%;
}
@media screen and (min-width: 680px) {
  .mc-modal__dialog {
    max-height: 50%;
    max-width: 38.5rem;
  }
}
@media (min-width: 1024px) and (max-width: 1919px) {
  .mc-modal__dialog {
    max-height: 66.6666666667%;
  }
}
@media screen and (min-width: 1024px) {
  .mc-modal__dialog {
    max-width: 48rem;
  }
}
@media screen and (min-width: 1280px) {
  .mc-modal__dialog {
    max-width: 50rem;
  }
}
@media screen and (min-width: 1920px) {
  .mc-modal__dialog {
    max-width: 56.5rem;
  }
}
.mc-modal__dialog.is-open {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: visibility 0s linear 0s, opacity 0.4s ease, -webkit-transform 0.4s ease;
  transition: visibility 0s linear 0s, opacity 0.4s ease, -webkit-transform 0.4s ease;
  transition: visibility 0s linear 0s, transform 0.4s ease, opacity 0.4s ease;
  transition: visibility 0s linear 0s, transform 0.4s ease, opacity 0.4s ease, -webkit-transform 0.4s ease;
  visibility: visible;
}
.mc-modal__form {
  display: contents;
}
.mc-modal__header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1rem;
  min-height: 3.75rem;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  position: relative;
}
.mc-modal__header::after {
  background: #b3b3b3;
  content: "";
  display: block;
  margin: 0 auto;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
}
@media screen and (min-width: 680px) {
  .mc-modal__header {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1.5rem;
  }
}
.mc-modal__icon {
  fill: #666666;
  height: 1.5rem;
  width: 1.5rem;
}
.mc-modal__title {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  font-weight: 400;
  color: #666666;
  margin-bottom: 0;
  margin-top: 0;
}
.mc-modal__close {
  margin: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  -ms-flex-item-align: start;
      align-self: flex-start;
  background: transparent url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMnJlbSIgd2lkdGg9IjJyZW0iIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0iIzgwODA4MCI+PHBhdGggZD0iTTE3LjQxIDE2bDguOC04Ljc5YTEgMSAwIDEwLTEuNDItMS40MkwxNiAxNC41OWwtOC43OS04LjhhMSAxIDAgMDAtMS40MiAxLjQybDguOCA4Ljc5LTguOCA4Ljc5YTEgMSAwIDAwMCAxLjQyIDEgMSAwIDAwMS40MiAwbDguNzktOC44IDguNzkgOC44YTEgMSAwIDAwMS40MiAwIDEgMSAwIDAwMC0xLjQyeiIvPjwvc3ZnPg==') no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 2rem;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-left: auto;
  width: 2rem;
}
.mc-modal__close::after {
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 0 transparent;
          box-shadow: 0 0 0 0 transparent;
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: -webkit-box-shadow 200ms ease;
  transition: -webkit-box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
}
.mc-modal__close-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
}
.mc-modal__close:focus::after {
  -webkit-box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
          box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
}
.mc-modal__body {
  font-size: 1rem;
  line-height: 1.375;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  color: #191919;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media screen and (min-width: 680px) {
  .mc-modal__body {
    padding-left: 0.75rem;
    padding-right: 1rem;
  }
}
.mc-modal__content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #666666 #e6e6e6;
  scrollbar-width: thin;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media screen and (min-width: 680px) {
  .mc-modal__content {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
.mc-modal__content::-webkit-scrollbar {
  background-color: #e6e6e6;
  width: 0.25rem;
}
.mc-modal__content::-webkit-scrollbar-thumb {
  background: #666666;
}
.mc-modal__heading {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
}
.mc-modal__inner {
  padding-top: 1.5rem;
  padding-bottom: 0rem;
}
.mc-modal__footer {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 1rem;
}
@media screen and (min-width: 680px) {
  .mc-modal__footer {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding: 1.5rem;
  }
}
.mc-modal__footer > :first-child:not(:only-child) {
  margin-bottom: 0.75rem;
}
@media screen and (min-width: 680px) {
  .mc-modal__footer > :first-child:not(:only-child) {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}
.mc-modal-open {
  overflow: hidden;
}
.mc-modal-overlay {
  background-color: rgba(25, 25, 25, 0.7);
  -webkit-filter: blur(1px);
          filter: blur(1px);
  inset: 0;
  opacity: 0;
  position: fixed;
  pointer-events: none;
  -webkit-transition: opacity 0.4s ease, visibility 0ms 0.4s;
  transition: opacity 0.4s ease, visibility 0ms 0.4s;
  z-index: 1999999998;
}
.mc-modal-overlay.is-visible {
  opacity: 1;
  pointer-events: all;
  -webkit-transition: opacity 0.4s ease, visibility 0ms;
  transition: opacity 0.4s ease, visibility 0ms;
  visibility: visible;
}
.mc-button {
  margin: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #ffffff;
  background-color: #188803;
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375;
  padding: 0.6875rem 1.5rem;
  min-height: 3rem;
  min-width: 3rem;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  border: 2px solid transparent;
  -webkit-transition: all ease 200ms;
  transition: all ease 200ms;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  vertical-align: middle;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  fill: currentColor;
}
.mc-button.is-hover,
.mc-button:hover {
  background-color: #006902;
  color: #ffffff;
}

.mc-button.is-active,
.mc-button:active {
  background-color: #006902;
}

.mc-button:disabled, .mc-button.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button .mc-button__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.mc-button .mc-button__icon:first-child, .mc-button .mc-button__icon:last-child {
  margin-bottom: -1px;
  margin-top: -1px;
}
.mc-button .mc-button__icon:only-child {
  margin-bottom: 0;
  margin-top: 0;
  width: 2rem;
  height: 2rem;
}
.mc-button.is-focus,
.mc-button:focus {
  -webkit-box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
          box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
}

.mc-button--s {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  padding: 0.3125rem 1rem;
  min-height: 2rem;
  min-width: 2rem;
}
.mc-button--s .mc-button__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.mc-button--s .mc-button__icon:first-child, .mc-button--s .mc-button__icon:last-child {
  margin-bottom: -0.1875rem;
  margin-top: -0.1875rem;
}
.mc-button--s .mc-button__icon:only-child {
  margin-bottom: 0;
  margin-top: 0;
  width: 1.5rem;
  height: 1.5rem;
}
.mc-button--m {
  font-size: 1rem;
  line-height: 1.375;
  padding: 0.6875rem 1.5rem;
  min-height: 3rem;
  min-width: 3rem;
}
.mc-button--m .mc-button__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.mc-button--m .mc-button__icon:first-child, .mc-button--m .mc-button__icon:last-child {
  margin-bottom: -1px;
  margin-top: -1px;
}
.mc-button--m .mc-button__icon:only-child {
  margin-bottom: 0;
  margin-top: 0;
  width: 2rem;
  height: 2rem;
}
.mc-button--l {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  padding: 0.875rem 1.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
}
.mc-button--l .mc-button__icon {
  width: 2rem;
  height: 2rem;
}
.mc-button--l .mc-button__icon:first-child, .mc-button--l .mc-button__icon:last-child {
  margin-bottom: -0.25rem;
  margin-top: -0.25rem;
}
.mc-button--l .mc-button__icon:only-child {
  margin-bottom: 0;
  margin-top: 0;
  width: 2rem;
  height: 2rem;
}
.mc-button--fit {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
}
.mc-button--full {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
@supports ((width: -webkit-fill-available) or (width: -moz-available) or (width: stretch)) {
  .mc-button--full {
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
  }
}
.mc-button--square {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 0;
  padding: 0;
}
.mc-button__icon {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.mc-button__icon:last-child {
  margin-left: 0.5rem;
  margin-right: -0.25rem;
}
.mc-button__icon:first-child {
  margin-right: 0.5rem;
  margin-left: -0.25rem;
}
.mc-button__icon:only-child {
  margin: 0;
}
.mc-button__label {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  pointer-events: none;
}
a.mc-button:disabled, a.mc-button.is-disabled {
  pointer-events: none;
}

.mc-button--solid-primary-02 {
  background-color: #6a7081;
}
.mc-button--solid-primary-02.is-hover,
.mc-button--solid-primary-02:hover {
  background-color: #242938;
}

.mc-button--solid-primary-02.is-active,
.mc-button--solid-primary-02:active {
  background-color: #171b26;
}

.mc-button--solid-primary-02:disabled, .mc-button--solid-primary-02.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--solid-neutral {
  background-color: #333333;
}
.mc-button--solid-neutral.is-hover,
.mc-button--solid-neutral:hover {
  background-color: #191919;
}

.mc-button--solid-neutral.is-active,
.mc-button--solid-neutral:active {
  background-color: #333333;
}

.mc-button--solid-neutral:disabled, .mc-button--solid-neutral.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--solid-danger {
  background-color: #c61112;
}
.mc-button--solid-danger.is-hover,
.mc-button--solid-danger:hover {
  background-color: #8c0003;
}

.mc-button--solid-danger.is-active,
.mc-button--solid-danger:active {
  background-color: #8c0003;
}

.mc-button--solid-danger:disabled, .mc-button--solid-danger.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--bordered {
  color: #188803;
  border-color: #188803;
  background-color: #ffffff;
}
.mc-button--bordered.is-hover,
.mc-button--bordered:hover {
  background-color: #ebf5de;
  color: #006902;
}

.mc-button--bordered.is-active,
.mc-button--bordered:active {
  background-color: #c5e39e;
  color: #035010;
}

.mc-button--bordered.is-active,
.mc-button--bordered:active {
  background-color: #c5e39e;
  color: #035010;
}

.mc-button--bordered:disabled, .mc-button--bordered.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--bordered-primary-02 {
  color: #6a7081;
  border-color: #6a7081;
  background-color: #ffffff;
}
.mc-button--bordered-primary-02.is-hover,
.mc-button--bordered-primary-02:hover {
  background-color: #eeeff1;
  color: #6a7081;
}

.mc-button--bordered-primary-02.is-active,
.mc-button--bordered-primary-02:active {
  background-color: #cfd2d8;
}

.mc-button--bordered-primary-02:disabled, .mc-button--bordered-primary-02.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--bordered-neutral {
  color: #333333;
  border-color: #333333;
  background-color: #ffffff;
}
.mc-button--bordered-neutral.is-hover,
.mc-button--bordered-neutral:hover {
  background-color: #e6e6e6;
  color: #333333;
}

.mc-button--bordered-neutral.is-active,
.mc-button--bordered-neutral:active {
  background-color: #cccccc;
}

.mc-button--bordered-neutral:disabled, .mc-button--bordered-neutral.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}
.mc-button--bordered-danger {
  color: #c61112;
  border-color: #c61112;
  background-color: #ffffff;
}
.mc-button--bordered-danger.is-hover,
.mc-button--bordered-danger:hover {
  background-color: #fdeaea;
  color: #8c0003;
}

.mc-button--bordered-danger.is-active,
.mc-button--bordered-danger:active {
  background-color: #f8bcbb;
  color: #530000;
}

.mc-button--bordered-danger.is-active,
.mc-button--bordered-danger:active {
  background-color: #f8bcbb;
  color: #530000;
}

.mc-button--bordered-danger:disabled, .mc-button--bordered-danger.is-disabled {
  background-color: #cccccc;
  border-color: transparent;
  color: #666666;
  cursor: not-allowed;
}

@media screen and (min-width: 680px) {
  .mc-button--s\@from-m {
    font-size: 0.875rem;
    line-height: 1.2857142857;
    padding: 0.3125rem 1rem;
    min-height: 2rem;
    min-width: 2rem;
  }
  .mc-button--s\@from-m .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--s\@from-m .mc-button__icon:first-child, .mc-button--s\@from-m .mc-button__icon:last-child {
    margin-bottom: -0.1875rem;
    margin-top: -0.1875rem;
  }
  .mc-button--s\@from-m .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-m {
    font-size: 1rem;
    line-height: 1.375;
    padding: 0.6875rem 1.5rem;
    min-height: 3rem;
    min-width: 3rem;
  }
  .mc-button--m\@from-m .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-m .mc-button__icon:first-child, .mc-button--m\@from-m .mc-button__icon:last-child {
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .mc-button--m\@from-m .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-m {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    padding: 0.875rem 1.5rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
  }
  .mc-button--l\@from-m .mc-button__icon {
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-m .mc-button__icon:first-child, .mc-button--l\@from-m .mc-button__icon:last-child {
    margin-bottom: -0.25rem;
    margin-top: -0.25rem;
  }
  .mc-button--l\@from-m .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--fit\@from-m {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
  }
  .mc-button--full\@from-m {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  @supports ((width: -webkit-fill-available) or (width: -moz-available) or (width: stretch)) {
    .mc-button--full\@from-m {
      width: -webkit-fill-available;
      width: -moz-available;
      width: stretch;
    }
  }
}

@media screen and (min-width: 1024px) {
  .mc-button--s\@from-l {
    font-size: 0.875rem;
    line-height: 1.2857142857;
    padding: 0.3125rem 1rem;
    min-height: 2rem;
    min-width: 2rem;
  }
  .mc-button--s\@from-l .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--s\@from-l .mc-button__icon:first-child, .mc-button--s\@from-l .mc-button__icon:last-child {
    margin-bottom: -0.1875rem;
    margin-top: -0.1875rem;
  }
  .mc-button--s\@from-l .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-l {
    font-size: 1rem;
    line-height: 1.375;
    padding: 0.6875rem 1.5rem;
    min-height: 3rem;
    min-width: 3rem;
  }
  .mc-button--m\@from-l .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-l .mc-button__icon:first-child, .mc-button--m\@from-l .mc-button__icon:last-child {
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .mc-button--m\@from-l .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-l {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    padding: 0.875rem 1.5rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
  }
  .mc-button--l\@from-l .mc-button__icon {
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-l .mc-button__icon:first-child, .mc-button--l\@from-l .mc-button__icon:last-child {
    margin-bottom: -0.25rem;
    margin-top: -0.25rem;
  }
  .mc-button--l\@from-l .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--fit\@from-l {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
  }
  .mc-button--full\@from-l {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  @supports ((width: -webkit-fill-available) or (width: -moz-available) or (width: stretch)) {
    .mc-button--full\@from-l {
      width: -webkit-fill-available;
      width: -moz-available;
      width: stretch;
    }
  }
  .mc-button--square {
    padding: 0;
  }
}

@media screen and (min-width: 1280px) {
  .mc-button--s\@from-xl {
    font-size: 0.875rem;
    line-height: 1.2857142857;
    padding: 0.3125rem 1rem;
    min-height: 2rem;
    min-width: 2rem;
  }
  .mc-button--s\@from-xl .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--s\@from-xl .mc-button__icon:first-child, .mc-button--s\@from-xl .mc-button__icon:last-child {
    margin-bottom: -0.1875rem;
    margin-top: -0.1875rem;
  }
  .mc-button--s\@from-xl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-xl {
    font-size: 1rem;
    line-height: 1.375;
    padding: 0.6875rem 1.5rem;
    min-height: 3rem;
    min-width: 3rem;
  }
  .mc-button--m\@from-xl .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-xl .mc-button__icon:first-child, .mc-button--m\@from-xl .mc-button__icon:last-child {
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .mc-button--m\@from-xl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-xl {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    padding: 0.875rem 1.5rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
  }
  .mc-button--l\@from-xl .mc-button__icon {
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-xl .mc-button__icon:first-child, .mc-button--l\@from-xl .mc-button__icon:last-child {
    margin-bottom: -0.25rem;
    margin-top: -0.25rem;
  }
  .mc-button--l\@from-xl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--fit\@from-xl {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
  }
  .mc-button--full\@from-xl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  @supports ((width: -webkit-fill-available) or (width: -moz-available) or (width: stretch)) {
    .mc-button--full\@from-xl {
      width: -webkit-fill-available;
      width: -moz-available;
      width: stretch;
    }
  }
}

@media screen and (min-width: 1920px) {
  .mc-button--s\@from-xxl {
    font-size: 0.875rem;
    line-height: 1.2857142857;
    padding: 0.3125rem 1rem;
    min-height: 2rem;
    min-width: 2rem;
  }
  .mc-button--s\@from-xxl .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--s\@from-xxl .mc-button__icon:first-child, .mc-button--s\@from-xxl .mc-button__icon:last-child {
    margin-bottom: -0.1875rem;
    margin-top: -0.1875rem;
  }
  .mc-button--s\@from-xxl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-xxl {
    font-size: 1rem;
    line-height: 1.375;
    padding: 0.6875rem 1.5rem;
    min-height: 3rem;
    min-width: 3rem;
  }
  .mc-button--m\@from-xxl .mc-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .mc-button--m\@from-xxl .mc-button__icon:first-child, .mc-button--m\@from-xxl .mc-button__icon:last-child {
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .mc-button--m\@from-xxl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-xxl {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    padding: 0.875rem 1.5rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
  }
  .mc-button--l\@from-xxl .mc-button__icon {
    width: 2rem;
    height: 2rem;
  }
  .mc-button--l\@from-xxl .mc-button__icon:first-child, .mc-button--l\@from-xxl .mc-button__icon:last-child {
    margin-bottom: -0.25rem;
    margin-top: -0.25rem;
  }
  .mc-button--l\@from-xxl .mc-button__icon:only-child {
    margin-bottom: 0;
    margin-top: 0;
    width: 2rem;
    height: 2rem;
  }
  .mc-button--fit\@from-xxl {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
  }
  .mc-button--full\@from-xxl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  @supports ((width: -webkit-fill-available) or (width: -moz-available) or (width: stretch)) {
    .mc-button--full\@from-xxl {
      width: -webkit-fill-available;
      width: -moz-available;
      width: stretch;
    }
  }
}
.example {
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 400;
  background-color: #f6f7f7;
  min-height: 500px;
  height: 100vh;
}

.mc-modal__content p {
  margin-top: 0;
  margin-bottom: 0;
}
.mc-modal__content p:not(:last-child) {
  margin-bottom: 1rem;
}