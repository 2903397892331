/* LoadingSpinner.css */

.loading-spinner {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Black background with 70% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above other content */
}

.loading-spinner__circle {
  width: 50px; /* Adjust the width and height as needed */
  height: 50px;
  animation: spin .9s linear infinite; /* Rotate animation */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:64px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
