
.und-price {
  font-size: 13px !important;
}
@media screen and (min-width: 800px) and (max-height:1500px) {
  .product-modal-name {}

  .und-price {
    font-size: 20px !important;
  }
  .read-product-icon {
    height: 32px;
    width: 32px;
  }

  .flexDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }


  .product-modal-price {
    color: var(--text-beta-secondary, #333);
    text-align: center;
    font-size: 16px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  .product-modal-green-price {
    color: #188803;
    text-align: center;
    font-size: 14px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .price-und-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .quantity-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .und {
    color: #188803;
    font-size: 12px;
  }

  .und-icon {
    width: 32px;
    height: 32px;
  }

  .read-product {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    margin-bottom: 25px;
    align-items: center;
  }

  .read-discount-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-image {
    width: 200px;
    height: 200px;
  }

  .product-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .modal-product-container {
    min-height: 50vh;
    min-width: 50vw;
  }
  .prduct-button-pop-up {
    height: 36px;
    min-width: 200px !important;
  }
  .product-modal-list-icon {

    width: 20px;
    height: 20px;
   
  } 

}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {
  .product-modal-name {
    margin-top: 10px !important;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: -1px !important;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 400;
  }
  
  .product-modal-list-icon {
    margin-top: 10px !important;
    width: 20px;
    height: 20px;
   
  } 

  .flexDiv {
    display: grid;
    flex-direction: row; 
    justify-content: space-between;
  }

  .product-modal-price {
    color: var(--text-beta-secondary, #333);
    text-align: center;
    font-size: 14px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    padding-left: 50px;
    padding-right: 50px;
    
  }

  .product-modal-green-price {
    color: #188803;
    text-align: center;
    font-size: 10px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 400;

    padding-left: 50px;
    padding-right: 50px;
  }

  .price-und-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-right: 50px;
  }

  .quantity-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .und {
    color: #188803;
    font-size: 12px;
  }

  .und-icon {
    width: 17px;
    height: 17px;
  }

  .read-product {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 14px;
    align-items: center;
  }

  .read-discount-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-image {
    width: 150px;
    height: 160px;
  }

  .product-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .read-product-icon {
    height: 16px;
    width: 16px;
  }

  .modal-product-container {
    min-height: 50vh;
    min-width: 70vw;
  }
  .prduct-button-pop-up {
    height: 48px;
    font-size: 16px !important;
    min-width: 175px !important;
    
  }

}


@media screen and (min-height: 1500px) {
  .modal-product-container {
    min-height: 1400px;
    min-width: 90vw;
  }

  .flexDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .product-modal-list-icon {
  
    width: 50px;
    height: 50px;
   
  } 

  .product-modal-name {
    color: var(--text-beta-secondary, #333);
    font-size: 48px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 400;
  }

  .product-modal-price {
    color: var(--text-beta-secondary, #333);
    text-align: center;
    font-size: 56px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 600;
  }

  .product-modal-green-price {
    color: #188803;
    text-align: center;
    font-size: 40px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 400;
  }

  .price-und-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .quantity-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .und {
    color: #188803;
    font-size: 24px;
  }

  .und-price {
    font-size: 40px !important;
  }

  .und-icon {
    height: 60px;
    width: 60px;
  }

  .read-product {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    margin-bottom: 25px;
    align-items: center;
    font-size: 32px;
  }

  .read-discount-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-image {
    height: 510px;
    margin-top: 80px;
  }

  .product-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .product-body-container {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 20px;
  }

  .prduct-button-pop-up {
    height: 68px;
    min-width: 330px !important;
    font-size: 30px !important
  }

  .read-product-icon {
    height: 60px;
    width: 60px;
  }
}