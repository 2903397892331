@media screen and (min-width: 800px) and (max-height:1500px) {
   .modal-price-title {
      align-self: center;
      text-align: center;

   }

   .modal-price-value {
      align-self: center;
      text-align: center;

      color: #188803
   }
   .modal-price-form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
   }
   .modal-price-input {
      text-align: center;
      margin-bottom: 20px !important;
   }
   .modal-price-container{
      min-width: 40vw;

      min-height: 60vh;
   }

}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {
   .modal-price-title {
      align-self: center;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
   }

   .modal-price-value {
      align-self: center;
      text-align: center;
      font-size: 16px;
      font-weight: 600;

      color: #188803
   }

   .modal-price-form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
   }

   .modal-price-label {
      font-size: 14px !important;
      margin-top: 20px !important;
   }

   .modal-price-input {
      text-align: center;
      margin-bottom: 20px !important;
   }
   .modal-price-container{
      min-width: 300px;

      min-height: 415px;
   }


}


@media screen and (min-height: 1500px) {
   .modal-price-title {
      align-self: center;
      text-align: center;
      font-size: 48px;
      font-weight: 400;
   }

   .modal-price-value {
      align-self: center;
      text-align: center;
      font-size: 56px;
      font-weight: 600;
      color: #188803
   }

   .modal-price-form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      padding-left: 50px;
      padding-right: 50px;
   }

 

   .modal-price-label {
      font-size: 36px !important;
      margin-top: 100px !important;
   }

   .modal-price-input {
      text-align: center;
   }
   .modal-price-container{
      min-width: 85vw;

      min-height: 60vh;
   }



}