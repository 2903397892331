.product-item {
  width: 90vw;
  max-height: 12vw;
  min-height: 64px;
  padding: 10px;
  background-color: white;
  opacity: 1;
  transition: opacity 0.3s ease; /* Adiciona uma transição suave */
  cursor: pointer; /* Muda o cursor para indicar que é clicável */
}

.product-item:active {
  opacity: 0.4; /* Define a opacidade quando o mouse está sobre o item */
}

@media screen and (min-height: 1200px) {
  .product-item {
    width: 90.5vw !important;
    max-height: 150px !important;
    height: 150px !important;
    min-height: 150px;
  }
  .product-item__title {
    font-size: 20px !important;
    width: 40vw !important;
    font-weight: 400;
  }
  .product-item__date {
    font-size: 15px !important;
    width: 200px !important;
    font-weight: 400;
  }
  .product-item__price {
    font-size: 20px !important;
    width: 111px !important;
    font-weight: 400;
  }
  .product-item__header {
    display: flex;
    flex-direction: column;
    margin-left: 20px !important;
  }

  .product-item__image img {
    width: 100%;
    max-height: 100%;
    height: auto;
min-width: 80px;

  }
  .product-item__time {
    font-size:17px !important;
  }
  .product-item__details {
    margin-left: 0px !important;
  }
  .product-item__image {
  
  }
}
.product-item__image {
  width: 10%;
  justify-content: center;

  align-items: center;
  display: flex;
}

.product-item__image img {
  height: 100%;

  max-height: 100%;
  height: auto;
}

.product-item__details {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
}

.product-item__header {
  display: flex;
  flex-direction: column;
}
.product-item__title {
  color: var(--text-beta-secondary, #333);
  font-size: 12px;
  font-family: "LeroyMerlin", sans-serif;
  margin: 4px;
  margin-left: 2px;
  display: flex;
  width: 140px;

  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.product-item__date {
  display: flex;
  width: 65px;

  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--primary-01600-default, #188803);
  font-size: 10px;
  font-family: "LeroyMerlin", sans-serif;
}

.product-item__time {
  display: flex;
  width: 54px;

  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--text-beta-primary, #000);
  text-align: right;
  font-size: 10px;
  font-family: "LeroyMerlin", sans-serif;
}

.product-item__price {
  display: flex;
  width: 70px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--text-beta-secondary, #333);
  text-align: center;
  font-size: 14px;
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
}

.product-item__quantity {
  border-radius: 4px;
  background: #c5e39e;
  width: 40px;
  height: 34px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
}

.product-item__quantity p {
  display: flex;
  width: 40px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--text-beta-secondary, #333);
  text-align: center;
  font-size: 14px;
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
}
