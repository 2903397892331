.mc-modal__header::after {
    background: #fff;
    content: "";
    display: block;
    margin: 0 auto;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
  }
.mc-button--m{
  margin-bottom: 5px !important;
}
  .mc-modal__dialog{
    max-height: fit-content;
    border-radius: 10px;
    max-width: fit-content;
    align-self: center;
  }

  .mc-modal__header{
    margin-bottom: 0px !important;
  }

  .mc-modal__inner{
    padding-top: 0px !important;
  }

  .modal__footer{
   
    background-color: red !important;
  }



  @media screen and (min-width: 800px ) and (max-height:1500px) {
    .mc-button-pop-up{
        width: 180px;
   
      }

  }
  
  /* On screens that are 600px or less, set the background color to olive */
  @media screen and (max-width: 800px) {
    .mc-button-pop-up{
        width: 150px;
        height:48px;
        margin-bottom: 15px;
      }
      .mc-modal{
        align-content: center;
      }
  
      .mc-modal__footer{
        flex-direction: column !important;
        padding-bottom: 25px;
        align-items: center;
      }
  }
  
  
  @media screen and (min-height: 1500px) {
    .mc-button-pop-up{
        width: 180px;
        height: 80px;
        min-width: 330px !important;
        margin-bottom: 15px;
      }
      .mc-button-pop-up span{
        font-size: 2rem
      }
    .mc-modal__dialog{

      padding-bottom: 80px;

    }
    .mc-modal__close{
      height: 50px !important;
      background-size: contain;
      width: 50px;
    }
  }



  