.mainHistory2 {
  padding-top: 75px;
  display: flex;
  height: 100%;
  flex-direction: column;
  
  top: 0px;
}

.titleHistory{
  color: var(--greyscale-800, #333);
text-align: center;
font-size: 20px;
font-family: LeroyMerlin;
font-style: normal;
font-weight: 400;
line-height: 24px;
margin-bottom: 60px;

}


.titleHistoryNoBold{
  color: var(--greyscale-800, #333);
  
font-size: 12px;
font-family: LeroyMerlin;
font-style: normal;
font-weight: 400;
/* margin-left: 10px;
margin-right: 10px; */
}




.SearchInputDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  background-color: white;
}

.SearchBar{
  position: fixed;
  background-color: white;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

@media screen and (min-width: 800px ) and (max-height:1500px) {
  .titleHistoryNoBold{
    color: var(--greyscale-800, #333);
    
  font-size: 12px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 400;
  /* margin-left: 10px;
  margin-right: 10px; */
  }
  .titleHistory2{
    color: var(--greyscale-800, #333);
  
  font-size: 12px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 600;
  }

  .HistoryTopDiv{
    background-color: white;
    padding-top: 1px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {
  .titleHistoryNoBold{
    color: var(--greyscale-800, #333);
    
  font-size: 12px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 400;
  /* margin-left: 10px;
  margin-right: 10px; */
  }

  .titleHistory2{
    color: var(--greyscale-800, #333);
  font-size: 12px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 600;
  }

  .HistoryTopDiv{
    background-color: white;
    padding-top: 1px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  
}


@media screen and (min-height: 1500px) {
  .titleHistoryNoBold{
    color: var(--greyscale-800, #333);
    
  font-size: 36px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 400;
  /* margin-left: 10px;
  margin-right: 10px; */
  }

  .titleHistory2{
    color: var(--greyscale-800, #333);
  
  font-size: 36px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 600;
  }

  .HistoryTopDiv{
    background-color: white;
    padding-top: 1px;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  
}