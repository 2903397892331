.div-input {
    width: 70vw;
    max-height: 70vh;
   
    
}
 
/* */
@media screen and (min-height: 1500px) {
   
   
    .cart-button-80 {
        width: 80px !important;
        height: 80px !important;
    }
    .cart-button img {
        width: 40px !important;
        height: 40px !important;
    }

}
    
