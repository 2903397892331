@media screen and (min-width: 800px) and (max-height:1500px) {


   .modal-cancela-question {
      align-self: center;
      text-align: center;
      font-size: 1rem;
   }

   .modal-cancela-product-container {
      max-width: 80vw;
      max-height: 300px;
   }



}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {


   .modal-cancela-question {
      align-self: center;
      text-align: center;

      padding-left: 20px;
      padding-right: 20px;
      padding-top: 30px;
   }

   .modal-cancela-product-container {
      max-width: 80vw;
      max-height: 300px;
   }



}


@media screen and (min-height: 1500px) {


   .modal-cancela-question {
      align-self: center;
      text-align: center;
      font-size: 48px;
      font-weight: 600;
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 120px;
   }

   .modal-cancela-product-container {
      max-width: 80vw;

   }

}