.mc-modal__header::after {
    background: #fff;
    content: "";
    display: block;
    margin: 0 auto;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
  }

  .mc-modal__dialog{
    max-height: min-content;
    border-radius: 10px;
    max-width: fit-content;
    align-self: center;
     
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
  }

  @media screen and (min-height: 375px) {
    .mc-modal__dialog{
     
      
    }
  }

  .mc-modal__header{
    margin-bottom: 0px !important;
  }

  .mc-modal__inner{
    padding-top: 0px !important;
  }

  .modal__footer{
   
    background-color: red !important;
  }



  @media screen and (min-width: 800px ) and (max-height:1500px) {
    .mc-button-pop-up{
        width: 180px;
      }
      .mc-button-pop-up-alternative{
        width: 180px;
        height: 48px;
        min-width: 330px !important;
        
      }

  }
  
  /* On screens that are 600px or less, set the background color to olive */
  @media screen and (max-width: 800px) {
    .mc-button-pop-up{
        width: 150px;
        height:48px
      }
      .mc-button-pop-up-alternative{
        width: 150px;
     

        height: 48px;
        min-width: 300px !important;
        /* font-size: 30px; */
        
      }

      .mc-modal{
        align-content: center;
      }
  
      .mc-modal__footer{
        flex-direction: column !important;
        padding-bottom: 25px;
        align-items: center;

      }
      .mc-modal__footer > :first-child:not(:only-child){
        margin-right: 0rem !important;
      }
  }
  
  
  @media screen and (min-height: 1500px) {
    .mc-button-pop-up{
        width: 180px;
        height: 80px;
        min-width: 330px !important;
      }
      .mc-button-pop-up span{
        font-size: 2rem
      }
    .mc-modal__dialog{

      padding-bottom: 80px;

    }
    .mc-modal__close{
      height: 50px !important;
      background-size: contain;
      width: 50px;
    }
    .mc-button-pop-up-alternative {
      width: 330px;
      height: 80px;
      min-width: 330px !important;
      font-size: 30px;
      white-space: nowrap; /* Impede que o texto quebre em várias linhas */
      overflow: hidden; /* Esconde o conteúdo que não cabe no botão */
      text-overflow: ellipsis; /* Adiciona reticências (...) para indicar texto truncado */
    }
    
  }



  