.DataList {
  padding: 20px;
  text-align: left;
}

.DataList > :nth-child(2) {
  font-size: 15px;
}

@media screen and (min-height: 1200px) {
  .List {
    height: 280px !important
  }
  .ListItem {
    height: 280px  !important
  }
}

.List {
  flex: 1;

  width: 100%;
  height: 84px;
}
.ListItem {
  position: relative;

  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  color: white;
  height: 84px;
  display: flex;
}

.Background {
  position: absolute;
  width: 98%;
  right: 1%;
  top: 1%;
  height: 100%;
  z-index: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  color: white;
  background-color: #b00000;
  padding-right: 30px;
}

.Wrapper {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  font-size: 18px;
}
.BouncingListItem {
  position: relative;
  justify-content: center;
  transition: transform 0.5s ease-out;
  width: 100%;
  z-index: 1;
  position: inherit;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  color: white;
  height: 84px;
  display: flex;
}
@media screen and (min-height: 1200px) {
  .BouncingListItem {
    height: 280px  !important
  }
}
.animal {
  color: yellow;
  font-size: 13px;
}

@media screen and (max-width: 1300px) {
  .Background {
    position: absolute;
    width: 100%;
    top: 1%;
    height: 100%;
    z-index: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
    color: white;
    background-color: #b00000;
    padding-right: 30px;
  }
}
