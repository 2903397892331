/* create columns */
/* create columns */
/* create custom named columns with custom content */
.mc-badge {
  border-radius: 4px;
  border: 1px solid transparent;
  display: inline-block;
  padding: 0.125rem 0.4375rem;
  text-transform: lowercase;
  white-space: nowrap;
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.2857142857;
  background-color: #daeff7;
  border-color: #0b96cc;
  color: #005c91;
}
.mc-badge::first-letter {
  text-transform: uppercase;
}
.mc-badge--success {
  background-color: #ebf5de;
  border-color: #46a610;
  color: #006902;
}
.mc-badge--warning {
  background-color: #fdf1e8;
  border-color: #ea7315;
  color: #8c3500;
}
.mc-badge--danger {
  background-color: #fdeaea;
  border-color: #ea302d;
  color: #8c0003;
}
.mc-badge--neutral {
  background-color: #e6e6e6;
  border-color: #808080;
  color: #4d4d4d;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Light.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Regular.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Italic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}