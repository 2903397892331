/* create columns */
/* create columns */
/* create custom named columns with custom content */
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Light.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Regular.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Italic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
.ml-container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
}
@media screen and (min-width: 680px) {
  .ml-container {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@media screen and (min-width: 1024px) {
  .ml-container {
    padding-right: 3rem;
    padding-left: 3rem;
    max-width: 64rem;
  }
}
@media screen and (min-width: 1280px) {
  .ml-container {
    padding-right: 2rem;
    padding-left: 2rem;
    max-width: 80rem;
  }
}
@media screen and (min-width: 1920px) {
  .ml-container {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    max-width: 120rem;
  }
}
.ml-container--fluid {
  max-width: none;
}
@media screen and (min-width: 680px) {
  .ml-container--fluid\@from-m {
    max-width: none;
  }
}
@media screen and (min-width: 1024px) {
  .ml-container--fluid\@from-l {
    max-width: none;
  }
}
@media screen and (min-width: 1280px) {
  .ml-container--fluid\@from-xl {
    max-width: none;
  }
}
@media screen and (min-width: 1920px) {
  .ml-container--fluid\@from-xxl {
    max-width: none;
  }
}
.mc-tabs {
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 400;
  background-color: #ffffff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  width: 100%;
}
.mc-tabs::after {
  background: #b3b3b3;
  content: "";
  display: block;
  margin: 0 auto;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
}
.mc-tabs--no-shadow::after, .mc-tabs--no-divider::after {
  display: none;
}
.mc-tabs__nav {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
}
.mc-tabs__nav, .mc-tabs__select {
  position: relative;
}
.mc-tabs__link, .mc-tabs__element {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  font-weight: 600;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: none;
  border: none;
  color: #666666;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 3.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  outline: none;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
  text-decoration: none;
}
.mc-tabs__link::before, .mc-tabs__element::before {
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 0 transparent;
          box-shadow: 0 0 0 0 transparent;
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: -webkit-box-shadow 200ms ease;
  transition: -webkit-box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
  bottom: -0.125rem;
  top: -0.125rem;
}
.mc-tabs__link::after, .mc-tabs__element::after {
  background-color: transparent;
  bottom: 0;
  content: "";
  height: 0.25rem;
  left: 1.5rem;
  position: absolute;
  right: 1.5rem;
}
.mc-tabs__link:hover .mc-tabs__text, .mc-tabs__element:hover .mc-tabs__text {
  background-color: #eeeff1;
}
.mc-tabs__link:active, .mc-tabs__element:active {
  color: #333333;
}
.mc-tabs__link:active .mc-tabs__text, .mc-tabs__element:active .mc-tabs__text {
  background-color: #cfd2d8;
}
.mc-tabs__link:focus::before, .mc-tabs__element:focus::before {
  -webkit-box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
          box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
}
.mc-tabs__link--selected, .mc-tabs__element--selected {
  color: #000000;
}
.mc-tabs__link--selected::after, .mc-tabs__element--selected::after {
  background-color: #000000;
}
.mc-tabs__link--disabled, .mc-tabs__element--disabled {
  cursor: not-allowed;
  background-color: #cccccc;
  pointer-events: none;
}
.mc-tabs__link--disabled, .mc-tabs__link--disabled:hover, .mc-tabs__link--disabled:focus, .mc-tabs__link--disabled:active, .mc-tabs__element--disabled, .mc-tabs__element--disabled:hover, .mc-tabs__element--disabled:focus, .mc-tabs__element--disabled:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.mc-tabs__text {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.25rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  padding: 0.4375rem 0.75rem;
  pointer-events: none;
}
.mc-tabs__icon {
  fill: currentColor;
  height: 1.5rem;
  width: 1.5rem;
}
.mc-tabs--full-centered {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.mc-tabs--dropdown {
  padding: 1rem;
  width: auto;
}

@media screen and (min-width: 680px) {
  .mc-tabs {
    width: auto;
  }
  .mc-tabs--full, .mc-tabs--full-centered {
    width: 100%;
  }
}

@media screen and (max-width: 679px) {
  .mc-tabs:not(.mc-tabs--dropdown)::before {
    bottom: 0.125rem;
  }
  .mc-tabs__nav {
    overflow: auto hidden;
    white-space: nowrap;
  }
}


.example {
  margin: 2rem 0;
}