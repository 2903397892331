@media screen and (min-width: 800px ) and (max-height:1500px) {

}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {

}


@media screen and (min-height: 1500px) {
 .mc-badge{
    font-size: 14px;
    min-height: 32px;
    min-width: 104px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    max-width: max-content;
 }
}