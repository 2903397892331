.mainCart {
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 0px;
}

.stateless {
  font-size: 16px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  margin-top: 50%;
}
.cart-stateless {
  width: 25%;
  height: 25%;
}
.cart-item-div {
  height: 10%;
  overflow-y: auto;
  margin-bottom: 130px;
}
  .bottomCart {
    position: fixed;
    bottom: 0px;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    background-color: white;
    margin: 0px;
    padding: 0px;
  }
  .cart_icon_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .cart_quantity {
    color: var(--text-beta-secondary, #333);
    text-align: center;
    font-size: 16px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-left: 5px;
  }
  .cart_total {
    color: var(--text-beta-secondary, #333);
    text-align: center;
    font-size: 16px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .cart_total_number {
    color: var(--text-beta-secondary, #333);
    text-align: center;
    font-size: 16px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  @media screen and (min-width: 800px ) and (max-height:1500px) {
    .buttons__div {
      display: flex;
      align-items: center;
   justify-content: center;
      width: 90vw;
      gap: 30px;
    }
    .cart-button{
      width: 330px;
    }

  }
  
  /* On screens that are 600px or less, set the background color to olive */
  @media screen and (max-width: 800px) {
    .buttons__div {
      display: flex;
      align-items: center;
      justify-content:center;
      width: 90vw;
      gap: 30px;
    }
    .cart-button{
      
      width: 80%;
    }
  }
  
  @media screen and (min-height: 1500px) {
    .buttons__div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 90vw;
      gap: 30px;
    }
    .cart-button{
      width: 330px;
    }
  }
  
  .quantity_price_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90vw;
  }

  
  .divider_cart {
    height: 0.3px;
    width: 100vw;
    background-color: black;
  }


.SearchInputDiv {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  background-color: white;
}

.SearchBar{
  position: fixed;
  background-color: white;
  padding-top: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
 
  
}

@media screen and (min-height: 375px) {
  .cart-div-search {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 90%;
    position: absolute;
    z-index: 6;
  }

  .cart-item-div {
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .cart-stateless {
    width: 20%;
    height: 20%;
  }
}

@media screen and (min-height: 1200px) {
  .cart-div-search {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 96%;
    position: absolute;
    z-index: 6;
  }

  .stateless {
    font-size: 48px;
    font-family: LeroyMerlin;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    margin-top: 50%;
  }
  .cart-stateless {
    width: 100%;
    height: 100%;
  }

  .cart-item-div {
    overflow: hidden;
    padding-top: 35px;
  }
}