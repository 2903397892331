@media screen and (min-width: 800px) and (max-height:1500px) {}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {

    #qty-selector {
        font-size: 22px;
    }

    .mc-button__icon {
        font-size: 32px !important
    }

    .mc-quantity-selector {
        height: 48px !important;
        border-width: 3px !important;

    }

    .mc-quantity-selector__button-left {
        height: 48px !important;
        width: 48px !important;
        border-width: 3px !important;

    }

    .mc-quantity-selector__button-right {
        height: 48px !important;
        width: 48px !important;
        border-width: 3px !important;
    }
}


@media screen and (min-height: 1500px) {
    #qty-selector {
        font-size: 24px;
    }

    .mc-button__icon {
        font-size: 34px !important
    }

    .mc-quantity-selector {
        height: 80px !important;
        border-width: 3px !important;

    }

    .mc-quantity-selector__button-left {
        height: 80px !important;
        width: 80px !important;
        border-width: 3px !important;

    }

    .mc-quantity-selector__button-right {
        height: 80px !important;
        width: 80px !important;
        border-width: 3px !important;
    }
}