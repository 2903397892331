/* create columns */
/* create columns */
/* create custom named columns with custom content */
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Light.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Regular.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Italic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
.mc-loader {
  color: #188803;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.mc-loader .mc-loader__spinner {
  height: 2rem;
  width: 2rem;
}
.mc-loader .mc-loader__path {
  stroke-width: 4;
}
.mc-loader .mc-loader__path {
  stroke: currentColor;
}
.mc-loader__spinner:not(:only-child) {
  margin-bottom: 1rem;
}
.mc-loader__icon {
  -webkit-animation: rotate-loader 2s linear infinite;
          animation: rotate-loader 2s linear infinite;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
}
.mc-loader__path {
  fill: none;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  -webkit-animation: animate-dash-loader 2s ease-in-out infinite;
          animation: animate-dash-loader 2s ease-in-out infinite;
}
.mc-loader__text {
  font-size: 1rem;
  line-height: 1.375;
  color: currentColor;
}
.mc-loader--s .mc-loader__spinner {
  height: 1.5rem;
  width: 1.5rem;
}
.mc-loader--s .mc-loader__path {
  stroke-width: 4;
}
.mc-loader--l .mc-loader__spinner {
  height: 4rem;
  width: 4rem;
}
.mc-loader--l .mc-loader__path {
  stroke-width: 8;
}
.mc-loader--dark {
  color: #191919;
}
.mc-loader--dark .mc-loader__path {
  stroke: currentColor;
}
.mc-loader--light {
  color: #ffffff;
}
.mc-loader--light .mc-loader__path {
  stroke: currentColor;
}
@-webkit-keyframes rotate-loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}
@keyframes rotate-loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}
@-webkit-keyframes animate-dash-loader {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes animate-dash-loader {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}