@media screen and (min-width: 800px) and (max-height:1500px) {
   .modal-api-config-container {
      min-height: 40vh;
      min-width: 50vw;
   }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {
   .modal-api-config-container {
      min-height: 300px;
      min-width: 300px;
   }
}


@media screen and (min-height: 1500px) {
   .api-config-input-label {
      font-size: 36px !important;

   }

   .modal-api-config-container {
      min-height: 600px;
      min-width: 80vw;
   }

}