/* stylelint-disable max-line-length */
/* create columns */
/* create columns */
/* create custom named columns with custom content */
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Light.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Regular.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-Italic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "LeroyMerlin";
  src: url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff2") format("woff2"), url("/public/fonts/LeroyMerlinSans-Web-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
.mc-link {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: inherit;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: inherit;
  text-decoration: underline;
  -webkit-transition: color ease 200ms;
  transition: color ease 200ms;
  gap: 0.25rem;
  color: #000000;
  position: relative;
  font-size: 1rem;
  line-height: 1.375;
}
.mc-link:focus {
  outline: none;
}
.mc-link:focus::after {
  -webkit-box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
          box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
}
.mc-link.is-visited,
.mc-link:visited {
  color: #333333;
}

.mc-link.is-hover,
.mc-link:hover {
  color: #666666;
}

.mc-link.is-active,
.mc-link:active {
  color: #333333;
}

.mc-link.is-disabled {
  color: #e6e6e6;
  cursor: not-allowed;
}
.mc-link--s {
  font-size: 0.875rem;
  line-height: 1.2857142857;
}
.mc-link--m {
  font-size: 1rem;
  line-height: 1.375;
}
.mc-link::after {
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 0 transparent;
          box-shadow: 0 0 0 0 transparent;
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: -webkit-box-shadow 200ms ease;
  transition: -webkit-box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
}
.mc-link__icon {
  display: block;
  height: 1rem;
  fill: currentColor;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1rem;
}
.mc-link--light {
  color: #ffffff;
}
.mc-link--light.is-visited,
.mc-link--light:visited {
  color: #cccccc;
}

.mc-link--light.is-hover,
.mc-link--light:hover {
  color: #999999;
}

.mc-link--light.is-active,
.mc-link--light:active {
  color: #cccccc;
}

.mc-link--light.is-disabled {
  color: #e6e6e6;
  cursor: not-allowed;
}
.mc-link--primary {
  color: #188803;
}
.mc-link--primary.is-visited,
.mc-link--primary:visited {
  color: #006902;
}

.mc-link--primary.is-hover,
.mc-link--primary:hover {
  color: #035010;
}

.mc-link--primary.is-active,
.mc-link--primary:active {
  color: #006902;
}

.mc-link--primary.is-disabled {
  color: #e6e6e6;
  cursor: not-allowed;
}
.mc-link--primary-02 {
  color: #494f60;
}
.mc-link--primary-02.is-visited,
.mc-link--primary-02:visited {
  color: #343b4c;
}

.mc-link--primary-02.is-hover,
.mc-link--primary-02:hover {
  color: #171b26;
}

.mc-link--primary-02.is-active,
.mc-link--primary-02:active {
  color: #343b4c;
}

.mc-link--primary-02.is-disabled {
  color: #e6e6e6;
  cursor: not-allowed;
}
.mc-link--danger {
  color: #c61112;
}
.mc-link--danger.is-visited,
.mc-link--danger:visited {
  color: #8c0003;
}

.mc-link--danger.is-hover,
.mc-link--danger:hover {
  color: #530000;
}

.mc-link--danger.is-active,
.mc-link--danger:active {
  color: #8c0003;
}

.mc-link--danger.is-disabled {
  color: #e6e6e6;
  cursor: not-allowed;
}

@media screen and (min-width: 680px) {
  .mc-link--s\@from-m {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
  .mc-link--m\@from-m {
    font-size: 1rem;
    line-height: 1.375;
  }
}

@media screen and (min-width: 1024px) {
  .mc-link--s\@from-l {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
  .mc-link--m\@from-l {
    font-size: 1rem;
    line-height: 1.375;
  }
}

@media screen and (min-width: 1280px) {
  .mc-link--s\@from-xl {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
  .mc-link--m\@from-xl {
    font-size: 1rem;
    line-height: 1.375;
  }
}

@media screen and (min-width: 1920px) {
  .mc-link--s\@from-xxl {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
  .mc-link--m\@from-xxl {
    font-size: 1rem;
    line-height: 1.375;
  }
}
.mc-notification {
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 400;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 1rem 1.5rem 1.5rem 1rem;
  background: #daeff7;
  -webkit-box-shadow: inset 0 0 0 1px #0b96cc;
          box-shadow: inset 0 0 0 1px #0b96cc;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #000000;
}
.mc-notification::before {
  background-size: 2rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2rem;
          flex: 0 0 2rem;
  height: 2rem;
  margin-right: 1rem;
  width: 2rem;
}
.mc-notification .mc-notification__content {
  padding-top: 0.25rem;
}
.mc-notification .mc-notification__title + .mc-notification__message {
  margin-top: 0.5rem;
}
.mc-notification::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2rem' width='2rem' viewBox='0 0 32 32' fill='%23007bb4'%3E%3Cpath d='M16,29A13,13,0,1,1,29,16,13,13,0,0,1,16,29ZM16,5A11,11,0,1,0,27,16,11,11,0,0,0,16,5Z'/%3E%3Cpath d='M16,21.7a1,1,0,0,1-1-1V14.87a1,1,0,1,1,2,0V20.7A1,1,0,0,1,16,21.7Z'/%3E%3Ccircle cx='16' cy='11.3' r='1'/%3E%3C/svg%3E");
}
.mc-notification::before {
  background-color: transparent;
  background-repeat: no-repeat;
  content: "";
  display: block;
}
.mc-notification__content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
}
.mc-notification__title, .mc-notification__message {
  margin-bottom: 0;
  margin-top: 0;
}
.mc-notification__title {
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
}
.mc-notification__message {
  font-size: 0.875rem;
  line-height: 1.5714285714;
}
.mc-notification__footer {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 1rem;
}
.mc-notification__link {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  margin-top: 0.5rem;
}
.mc-notification .mc-button:not(:first-child) {
  margin-top: 1rem;
}
.mc-notification--information {
  background: #daeff7;
  -webkit-box-shadow: inset 0 0 0 1px #0b96cc;
          box-shadow: inset 0 0 0 1px #0b96cc;
}
.mc-notification--information::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2rem' width='2rem' viewBox='0 0 32 32' fill='%23007bb4'%3E%3Cpath d='M16,29A13,13,0,1,1,29,16,13,13,0,0,1,16,29ZM16,5A11,11,0,1,0,27,16,11,11,0,0,0,16,5Z'/%3E%3Cpath d='M16,21.7a1,1,0,0,1-1-1V14.87a1,1,0,1,1,2,0V20.7A1,1,0,0,1,16,21.7Z'/%3E%3Ccircle cx='16' cy='11.3' r='1'/%3E%3C/svg%3E");
}
.mc-notification--success {
  background: #ebf5de;
  -webkit-box-shadow: inset 0 0 0 1px #46a610;
          box-shadow: inset 0 0 0 1px #46a610;
}
.mc-notification--success::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2rem' width='2rem' fill='%23188803' viewBox='0 0 32 32'%3E%3Cpath d='M16,5A11,11,0,1,1,5,16,11,11,0,0,1,16,5m0-2A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z'/%3E%3Cpath d='M14.2,21.45,8.5,16.18a1,1,0,1,1,1.35-1.47l4.28,4,8-8.09a1,1,0,0,1,1.42,0,1,1,0,0,1,0,1.41Z'/%3E%3C/svg%3E");
}
.mc-notification--warning {
  background: #fdf1e8;
  -webkit-box-shadow: inset 0 0 0 1px #ea7315;
          box-shadow: inset 0 0 0 1px #ea7315;
}
.mc-notification--warning::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2rem' width='2rem' viewBox='0 0 32 32' fill='%23c65200'%3E%3Cpath d='M16,29A13,13,0,1,1,29,16,13,13,0,0,1,16,29ZM16,5A11,11,0,1,0,27,16,11,11,0,0,0,16,5Z'/%3E%3Cpath d='M16,18.13a1,1,0,0,1-1-1V11.3a1,1,0,0,1,2,0v5.83A1,1,0,0,1,16,18.13Z'/%3E%3Ccircle cx='16' cy='20.7' r='1'/%3E%3C/svg%3E");
}
.mc-notification--danger {
  background: #fdeaea;
  -webkit-box-shadow: inset 0 0 0 1px #ea302d;
          box-shadow: inset 0 0 0 1px #ea302d;
}
.mc-notification--danger::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2rem' width='2rem' viewBox='0 0 32 32' fill='%23c61112'%3E%3Cpath d='M16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Zm0,24A11,11,0,1,1,27,16,11,11,0,0,1,16,27Z'/%3E%3Cpath d='M20.71,11.29a1,1,0,0,0-1.42,0L16,14.59l-3.29-3.3a1,1,0,0,0-1.42,1.42L14.59,16l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L16,17.41l3.29,3.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L17.41,16l3.3-3.29A1,1,0,0,0,20.71,11.29Z'/%3E%3C/svg%3E");
}
.mc-notification--s {
  padding: 0.75rem 0.75rem 1.5rem;
}
.mc-notification--s::before {
  background-size: 1.5rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 1.5rem;
          flex: 0 0 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
}
.mc-notification--s .mc-notification__content {
  padding-top: 0;
}
.mc-notification--s .mc-notification__title + .mc-notification__message {
  margin-top: 0.25rem;
}
.mc-notification--s::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1.5rem' width='1.5rem' viewBox='0 0 24 24' fill='%23007bb4'%3E%3Cpath d='M12 22a10 10 0 1110-10 10 10 0 01-10 10zm0-18a8 8 0 108 8 8 8 0 00-8-8z'/%3E%3Cpath d='M12 17a1 1 0 01-1-1v-4.39a1 1 0 012 0V16a1 1 0 01-1 1z'/%3E%3Ccircle cx='12' cy='8' r='1'/%3E%3C/svg%3E");
}
.mc-notification--s.mc-notification--success::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1.5rem' width='1.5rem' viewBox='0 0 24 24' fill='%23188803'%3E%3Cpath d='M12 4a8 8 0 11-8 8 8 8 0 018-8m0-2a10 10 0 1010 10A10 10 0 0012 2z'/%3E%3Cpath d='M10.73 15.75a1 1 0 01-.68-.26l-3-2.74a1 1 0 011.36-1.47l2.25 2.08 4.36-4.42a1 1 0 111.42 1.41l-5 5.1a1 1 0 01-.71.3z'/%3E%3C/svg%3E");
}
.mc-notification--s.mc-notification--warning::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1.5rem' width='1.5rem' viewBox='0 0 24 24' fill='%23c65200'%3E%3Cpath d='M12 2a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8 8 0 01-8 8z'/%3E%3Cpath d='M12 7a1 1 0 00-1 1v4.38a1 1 0 002 0V8a1 1 0 00-1-1z'/%3E%3Ccircle cx='12' cy='16' r='1'/%3E%3C/svg%3E");
}
.mc-notification--s.mc-notification--danger::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1.5rem' width='1.5rem' viewBox='0 0 24 24' fill='%23c61112'%3E%3Cpath d='M12 2a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8 8 0 01-8 8z'/%3E%3Cpath d='M16.21 7.88a1 1 0 00-1.42 0l-2.74 2.74-2.84-2.83a1 1 0 10-1.42 1.42L10.63 12l-2.77 2.79a1 1 0 001.41 1.42l2.78-2.77 2.68 2.68a1 1 0 001.41-1.42L13.46 12l2.75-2.7a1 1 0 000-1.42z'/%3E%3C/svg%3E");
}
.mc-notification-closable {
  position: relative;
}
.mc-notification-closable__close {
  position: relative;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1rem' width='1rem' fill='%23000000' viewBox='0 0 16 16'%3E%3Cpath d='M9.41 8l3.3-3.29a1 1 0 10-1.42-1.42L8 6.59l-3.29-3.3a1 1 0 00-1.42 1.42L6.59 8l-3.3 3.29a1 1 0 000 1.42 1 1 0 001.42 0L8 9.41l3.29 3.3a1 1 0 001.42 0 1 1 0 000-1.42z'/%3E%3C/svg%3E") no-repeat;
  background-size: 1rem;
  border: 0;
  cursor: pointer;
  display: block;
  height: 1rem;
  padding: 0;
  position: absolute;
  outline: 0;
  right: 1rem;
  top: 1rem;
  width: 1rem;
}
.mc-notification-closable__close::after {
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 0 transparent;
          box-shadow: 0 0 0 0 transparent;
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: -webkit-box-shadow 200ms ease;
  transition: -webkit-box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
}
.mc-notification-closable__close:focus::after {
  -webkit-box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
          box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc;
  -webkit-box-shadow: 0 0 0 0.125rem #0b96cc;
          box-shadow: 0 0 0 0.125rem #0b96cc;
}

@media screen and (min-width: 1024px) {
  .mc-notification__footer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .mc-notification .mc-button:not(:first-child) {
    margin-top: 0;
    margin-left: 1rem;
  }
}