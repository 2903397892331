@media screen and (min-width: 800px) and (max-height:1500px) {
   .modal-stock-title {
      align-self: center;
      text-align: center;
      font-weight: 600;
      margin-bottom: 0px;
      margin-top: 10px;

   }

   .stock-icon {
      height: 30px;
      width: 30px;
      align-self: center;
   }

   .modal-stock-header {
      display: flex;
      align-items: center;
      flex-direction: column;
   }

   .modal-stock-list-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;


   }

   .modal-stock-list-container {
      margin-top: 30px;
      padding-left: 50px;
      padding-right: 50px;
      max-height: 500px;
      overflow-y: scroll;
   }

   .modal-stock-item-price {
      font-weight: 600;
   }

   .modal-stock-divider {
      display: none;
   }

   .modal-stock-container {
      min-width: 60vw;
      min-height: 40vh;
   }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {
   .modal-stock-title {
      align-self: center;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      margin-top: 0px;
      margin-bottom: 0px;

   }

   .stock-icon {
      height: 24px;
      width: 24px;
      align-self: center;
   }

   .modal-stock-header {
      display: flex;
      align-items: center;
      flex-direction: column;
   }

   .modal-stock-list-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 12px;
      margin-bottom: 0px;
      margin-right: 2%;

   }

   .modal-stock-list-container {
      margin-top: 30px;

      padding-right: 20px;
      max-height: 340px;
      overflow-y: scroll;
   }

   .modal-stock-item-price {
      font-weight: 600;
   }

   .modal-stock-divider {
      height: 0.2px;
      background-color: black;
      margin-top: 0px;
      margin-bottom: 8px !important;

   }

   .modal-stock-item-name {
      margin-bottom: 8px !important;
   }

   .modal-stock-container {
      min-width: 90vw;
      min-height: 365px;
   }

}


@media screen and (min-height: 1500px) {
   .modal-stock-title {
      align-self: center;
      text-align: center;
      font-size: 48px;
      font-weight: 600;
      margin-bottom: 0px;
      margin-top: 20px;

   }

   .stock-icon {
      height: 60px;
      width: 60px;
      align-self: center;
   }

   .modal-stock-header {
      display: flex;
      align-items: center;
      flex-direction: column;
   }

   .modal-stock-list-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 40px;

   }

   .modal-stock-list-container {
      margin-top: 100px;
      padding-left: 50px;
      padding-right: 50px;
      max-height: 800px;
      overflow-y: scroll;
   }

   .modal-stock-item-price {
      font-weight: 600;
   }

   .modal-stock-divider {
      display: none;
   }

   .modal-stock-container {
      min-width: 90vw;
      min-height: 1300px;
   }

}