.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 64px;
  z-index: 100;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  transition: opacity 0.3s ease;
}
.overlay-animate {
  opacity: 1;
}
.header__menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 75%;
  height: 100%;
  background-color: #eeeff1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.header__menu.open {
  right: 0;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.header.menu-open .header__menu {
}

.menu__tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
}

.menu__tab {
  padding: 10px 20px;
  cursor: pointer;
}

.menu__tab:hover {
  background-color: #ddd;
}

.menu__tab.active {
  background-color: #fff;
  font-weight: bold;
}

.header__icon {
  padding: 4px;
  cursor: pointer;
}

.header__title {
  flex: 1;
  text-align: center;
  margin: 0;
  font-family: "LeroyMerlin", sans-serif;
  font-weight: 600;
  font-size: 1rem;

  color: var(--text-beta-secondary, #333);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
}

.header__icon-right {
  margin-left: auto;
  padding-right: 30px;
  cursor: pointer;
}

.header__icon-left {
  margin-left: auto;

  cursor: pointer;
}

@media screen and (max-width: 767px) {
  /* Estilos para telas menores que 768px */

  .header {
    height: 64px;
    /* Reduz a altura do cabeçalho */
  }
}

.header__bottom {
  position: relative;
  width: 100%;
  height: 89px;
  flex-shrink: 0;
  background: #cfd2d8;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.imageProfile {
  border-radius: 999px;
  display: flex;
  width: 47.928px;
  height: 47.928px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 20px;
}
.header__image_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header__loggout__icon {
  padding-right: 10px;
}
.NameTitle {
  overflow: hidden;
  color: var(--text-beta-primary, #000);
  text-overflow: ellipsis;
  white-space: nowrap;

  /* Leroy Merlin SemiBold/SemiBold 16 */
  font-size: 16px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
}

.NameDesc {
  margin-top: 7px;
  overflow: hidden;
  color: var(--text-beta-primary, #000);
  text-overflow: ellipsis;
  white-space: nowrap;

  /* Leroy Merlin Regular/Regular 14 */
  font-size: 14px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 400;
}

.header__divider {
  width: 70vw;
  position: relative;
  height: 1px;
  flex-shrink: 0;
  background-color: #6f676c;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header__back_arrow_div {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px;
}

.header__cancela {
  overflow: hidden;
  color: var(--text-beta-primary, #000);
  text-overflow: ellipsis;

  /* Leroy Merlin Regular/Regular 14 */
  font-size: 18px;
  font-family: LeroyMerlin;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.header__cancela {
  color: var(--text-beta-primary, #000);
  text-overflow: ellipsis;

  /* Leroy Merlin Regular/Regular 14 */
  font-size: 16px;
  font-family: LeroyMerlin;
  font-style: bold;
  font-weight: 600;
  line-height: 18px;
}


.header__row__cancela {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.header__icon {
  padding-right: 15px;
}

.header__container {
  min-height: 55vh;
}

.header__settings {
  position: absolute;
  bottom: 10vh;
  opacity: 1;
  transition: opacity 0.3s ease; /* Adiciona uma transição suave */
  cursor: pointer; /* Muda o cursor para indicar que é clicável */
}

.header__settings:active {
  opacity: 0.4; /* Define a opacidade quando o mouse está sobre o item */
}

.header__title__icon__cancela{
  display: flex;
  align-items: center;
  flex-direction: row;
}

@media screen and (min-width: 800px) and (max-height: 1500px) {
  .header__settings {
    position: absolute;
    bottom: 15vh;
  }
}

@media screen and (max-width: 800px) {
  .header__settings {
    position: absolute;
    bottom: 15vh;
  }
}

@media screen and (max-width: 767px) {
  /* Estilos para telas menores que 768px */
  .header {
    height: 64px;
    /* Reduz a altura do cabeçalho */
  }
}
