.mc-field__label{
    font-family: "LeroyMerlin", sans-serif;

}
.mc-field__help{
    font-family: "LeroyMerlin", sans-serif;

}

.mc-field__requirement{
    font-family: "LeroyMerlin", sans-serif;

}
.mc-field-select{
    min-width: 100% !important;
}

@media screen and (min-height: 1500px) {
    .mc-select {height: 80px;
    font-size: 24px !important;
    }
    .mc-select::placeholder {
        font-size: 24px !important;
    }

 
}
